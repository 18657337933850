.trend__card {
  .question {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.8px;
    color: #010b1d;
    opacity: 1;
  }

  .posted {
    font-family: Poppins;
    font-size: 12px;
    letter-spacing: 0.8px;
    opacity: 0.87;
    color: #bfbfbf;
  }

  .poster {
    color: #286cff;
  }
}
