.faq {
  .page__header {
    text-align: left;
    font-size: 32px;
    font-family: "Circular Std Black";
    letter-spacing: 2.5px;
    color: #010b1d;
    opacity: 1;
    text-align: center;
  }

  .page__content {
    font-size: 14px;
    font-family: Poppins;
    letter-spacing: 1.1px;
    color: #010b1d;
    opacity: 0.67;
    text-align: center;
  }

  .grey-bg {
    background-color: #eff0f2;
  }
}
