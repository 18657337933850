.benefits {
  background-color: #f7fafe;

  .main {
    text-align: left;
    font-size: 36px;
    font-family: "Circular Std Black";
    letter-spacing: 2.5px;
    color: #010b1d;
    opacity: 1;
  }

  .main__header {
    font-size: 16px;
    font-family: Poppins;
    font-weight: bold;
    letter-spacing: 1.1px;
    color: #010b1d;
    opacity: 0.87;
  }

  .mini__header {
    font-size: 14px;
    font-family: Poppins;
    letter-spacing: 1.1px;
    color: #010b1dab;
    opacity: 1;
  }

  .top__header {
    text-align: left;
    font-size: 18px;
    font-weight: bold;
    font-family: Poppins;
    letter-spacing: 1.1px;
    color: #ffaa00;
    opacity: 0.87;
  }

  hr {
    border: 1.25px solid #ffaa00;
  }
}

.investment {
  .bottom_bg {
    background: #f5f6f8 0% 0% no-repeat padding-box;
  }

  .top__header {
    text-align: left;
    font-size: 18px;
    font-weight: bold;
    font-family: Poppins;
    letter-spacing: 1.1px;
    color: #ffaa00;
    opacity: 0.87;
  }

  hr {
    border: 1.25px solid #ffaa00;
  }

  .main {
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    font-family: Poppins;
    letter-spacing: 1.1px;
    color: #010b1d;
    opacity: 0.87;
  }

  .mini {
    font-size: 12px;
    font-family: Poppins;
    letter-spacing: 1.1px;
    color: #010b1dab;
    opacity: 1;
  }

  .right__main {
    font-size: 36px;
    font-family: "Circular Std Black";
    letter-spacing: 2.5px;
    color: #010b1d;
    opacity: 1;
    max-width: 300px;
  }

  .right__content {
    font-size: 14px;
    font-family: Poppins;
    letter-spacing: 1.1px;
    color: #010b1d;
    opacity: 0.87;
    max-width: 50px;
  }
}
.yellow {
  background-color: #ffaa00;
}
