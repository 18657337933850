.thread__card {
  .question__title {
    font-family: Poppins;
    font-size: 15px;
    font-weight: semi-bold;
    letter-spacing: 0.95px;
    color: #010b1d;
    opacity: 1;
  }

  .posted {
    font-family: Poppins;
    font-size: 12px;
    letter-spacing: 0.8px;
    opacity: 0.87;
    color: #bfbfbf;
  }

  .question__body {
    text-align: left;
    font-family: Poppins;
    font-size: 13px;
    letter-spacing: 0.95px;
    color: #010b1d;
    opacity: 0.77;
  }

  .poster {
    color: #286cff;
  }
}
