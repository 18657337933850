.auth__header {
  .text {
    font-size: 14px;
    font-family: Poppins;
    letter-spacing: 0.46px;
    color: #0b0547;
    opacity: 1;
  }

  .nav__button {
    background: #e9f0ff 0% 0% no-repeat padding-box;
    border: 2px solid #286cff;
    border-radius: 5px;
    opacity: 1;
    padding: 15px;
    color: #2870fd;
  }
}

.quote {
  font-size: 14px;
  font-family: Poppins;
  letter-spacing: 1.08px;
  color: #ffffff;
  opacity: 0.89;
}

.quote__name {
  font-size: 14px;
  font-family: Poppins;
  font-weight: bold;
  letter-spacing: 1.54px;
  color: #ffffff;
  opacity: 1;
}
