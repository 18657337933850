.dashboard__page {
  .name {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 157.5%;
    letter-spacing: 0.06em;
    color: #000000;
  }
  .others {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 157.5%;
    letter-spacing: 0.06em;
    color: #000000;
  }

  .top__wrapper {
    background: #f7fafe;
  }

  .card__title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #ffffff;
  }

  .card__lessons {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #ffffff;
  }

  .card__duration {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    text-transform: capitalize;
    color: #ffffff;
  }

  .top__text {
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0.06em;
    color: rgba(0, 0, 0, 0.65);
  }
}
