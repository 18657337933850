.lessons__page {
  background: #f7fafe;
  font-family: Poppins;

  .step {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 157.5%;
    letter-spacing: 0.06em;
    color: rgba(229, 229, 229, 0.8);
  }

  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 157.5%;
    letter-spacing: 0.06em;
    color: rgba(0, 0, 0, 0.76);
  }

  .duration {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 157.5%;
    letter-spacing: 0.06em;
    color: #c4c4c4;
  }

  .form__border {
    background: #f8f8f8 0% 0% no-repeat padding-box;
    border: 1px solid #0b054733;
    border-radius: 3px;
    opacity: 1;
    padding: 10px;
    outline: 0;
  }

  .top__text {
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0.06em;
    color: rgba(0, 0, 0, 0.65);
  }

  .forum__subheader {
    font-size: 15px;
    letter-spacing: 1.4px;
    color: #010b1d;
    max-width: 600px;
    opacity: 0.77;
    margin: auto;
  }

  .header__text {
    font-family: "Circular Std Black";
    font-size: 32px;
    letter-spacing: 2.5px;
    color: #010b1d;
    opacity: 1;
    max-width: 600px;
  }
}
