.authentication {
  .form__header {
    font-size: 22px;
    font-family: Poppins;
    letter-spacing: 1px;
    font-weight: bold;
    color: #2870fd;
    opacity: 1;
  }

  .form__subheadline {
    font-size: 13px;
    font-family: Poppins;
    letter-spacing: 0.77px;
    color: #0b0547;
    opacity: 1;
  }

  .form__label {
    font-size: 13px;
    font-weight: semibold;
    font-family: Poppins;
    letter-spacing: 0.77px;
    opacity: 1;
  }

  .form__border {
    background: #f8f8f8 0% 0% no-repeat padding-box;
    border: 1px solid #0b054733;
    border-radius: 3px;
    opacity: 1;
    padding: 10px;
    outline: 0;
  }

  .form__border__mini {
    background: #f8f8f8 0% 0% no-repeat padding-box;
    border: 1px solid #0b054733;
    border-radius: 3px;
    opacity: 1;
    width: 40px !important;
    height: 40px !important;
    margin: auto 10px auto auto;
    color: black;
  }

  input,
  select {
    text-align: left;
    font-size: 13px;
    font-weight: semibold;
    font-family: Poppins;
    letter-spacing: 0.75px;
    color: #0b0547;
    opacity: 1;
  }

  .unfilled__progress {
    background-color: #e9f0ff;
  }

  .form__muted {
    font-size: 12px;
    font-family: Poppins;
    letter-spacing: 0.75px;
    color: #767398;
  }

  .yellow__text {
    color: #ffa200;
  }

  .blue__text {
    color: #2870fd;
    text-decoration: underline;
    cursor: pointer;
  }
}

.error__message {
  font-size: 12px;
  font-family: Poppins;
  color: red;
}
