.post_page {
  .post__header {
    text-align: left;
    font-family: "Circular Std Black";
    font-size: 32px;
    letter-spacing: 2.5px;
    color: #010b1d;
    opacity: 1;
    margin: auto;
  }
  .post__subheader {
    text-align: left;
    font-family: Poppins;
    font-size: 15px;
    letter-spacing: 1.4px;
    color: #010b1d;
    opacity: 0.77;
    margin: auto;
  }

  .form__border {
    background: #f8f8f8 0% 0% no-repeat padding-box;
    border: 1px solid #0b054733;
    border-radius: 3px;
    opacity: 1;
    padding: 15px;
    margin: auto;
  }

  input,
  textarea {
    text-align: left;
    font-size: 11px;
    font-weight: semibold;
    font-family: Poppins;
    letter-spacing: 0.75px;
    color: #0b0547;

    opacity: 1;
  }

  .trending {
    text-align: left;
    font-family: Poppins;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.95px;
    color: #010b1d;
    opacity: 1;
  }

  .blue__text {
    color: #286cff;
  }

  .limit__width {
    max-width: 600px;
    margin: auto;
  }

  .posted {
    font-family: Poppins;
    font-size: 12px;
    letter-spacing: 0.8px;
    opacity: 0.87;
    color: #bfbfbf;
  }
}
