.preview__card {
  font-family: Poppins;
  .name {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.8px;
    color: #010b1d;
    opacity: 1;
  }

  .desc {
    font-size: 12px;
    letter-spacing: 0.8px;
    opacity: 0.87;
    color: #bfbfbf;
  }

  .poster {
    color: #286cff;
  }
}
