.trending {
  text-align: left;
  font-family: Poppins;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.95px;
  color: #010b1d;
  opacity: 1;
}

.chat__container {
  position: relative;
  height: 500px;
  overflow-y: scroll;
}

.left__container {
  height: 600px;
  overflow-y: scroll;
}

.chat__input {
  position: fixed;
  bottom: 0;
}
