.subscription__page {
  background: #f7fafe;
  font-family: Poppins;

  .header__text {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.05em;
    color: #e5e5e5;
  }

  .side__text {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.05em;
  }

  .subscription__title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
  }

  .subscription__lessons {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
  }

  .subscription__duration {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    text-transform: capitalize;
  }
}
