.pricing {
  .wrapper {
    background-color: #f7fafe;
  }
  .mini__header {
    font: normal normal bold 14px Poppins;
    letter-spacing: 1.1px;
    color: #ffaa00;
    opacity: 0.87;
  }

  .header {
    font: normal normal normal 36px "Circular Std Black";
    letter-spacing: 2.5px;
    color: #010b1d;
    opacity: 1;
    max-width: 500px;
  }

  .content {
    font: normal normal normal 12px Poppins;
    letter-spacing: 1.1px;
    color: #010b1d;
    opacity: 0.87;
    max-width: 500px;
  }

  .subscription {
    .card__header {
      font: normal normal bold 14px Poppins;
      letter-spacing: 0.95px;
      color: #009c2a;
      opacity: 0.87;
      text-align: left;
    }

    .card__price {
      font: normal normal normal 32px "Circular Std Black";
      letter-spacing: 2.15px;
      text-align: left;
    }

    .user {
      font: normal normal normal 18px "Circular Std Black";
      letter-spacing: 1.05px;
    }

    .list {
      font: normal normal normal 14px Poppins;
      letter-spacing: 0.95px;
      opacity: 0.87;
      text-align: left;
    }
  }

  .discussion__title {
    font: normal normal normal 36px "Circular Std Black";
    letter-spacing: 2.5px;
    color: #010b1d;
    opacity: 1;
    max-width: 400px;
  }

  .green_bg {
    background-color: #009c2a;
  }

  .discussion__card__title {
    font: normal normal bold 14px Poppins;
    letter-spacing: 1.1px;
    color: #ffffff;
    opacity: 0.87;
  }

  .discussion__card__content {
    font: normal normal normal 14px Poppins;
    letter-spacing: 1.1px;
    color: #ffffff;
    opacity: 0.87;
  }
}

.move__top {
  margin-top: -8rem;
}
