.side__bar {
  .nav__link {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 157.5%;
    letter-spacing: 0.06em;
    color: rgba(0, 0, 0, 0.6);
  }

  .active__route {
    background: rgba(176, 201, 255, 0.16);
    border: 1px solid #286cff;
    color: #1b63ff;
    width: 100%;
    box-sizing: border-box;
  }
}
