.error_page {
  font-family: Poppins;

  letter-spacing: 0.06em;
  font-style: normal;
  .bottom__text {
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.06em;
    color: rgba(0, 0, 0, 0.65);
  }

  .code {
    font-weight: 500;
    font-size: 250px;
    color: rgba(0, 0, 0, 0.65);
  }

  .top__text {
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0.06em;
    color: rgba(0, 0, 0, 0.65);
  }
}
