.comment__card {
  .comment {
    font-family: Poppins;
    font-size: 13px;
    font-weight: normal;
    letter-spacing: 0.95px;
    color: #010b1d;
    opacity: 1;
    opacity: 0.87;
    line-height: 1.75em;
  }

  .posted {
    font-family: Poppins;
    font-size: 12px;
    letter-spacing: 0.8px;
    opacity: 0.87;
    color: #bfbfbf;
  }

  .poster {
    color: #286cff;
  }
}
