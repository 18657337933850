.header {
  .link__text {
    text-align: left;
    font-size: 14px;
    font-family: Poppins;
    font-weight: semi-bold;
    letter-spacing: 1px;
    color: #010b1d;
    opacity: 0.79;
  }
}
