.landing {
  .wrapper {
    min-height: 85vh;
  }

  .main__text {
    text-align: center;
    font-size: 48px;
    padding: 20px auto;
    font-family: "Circular Std Black";
    letter-spacing: 2.5px;
    color: #ffffff;
    opacity: 1;
    max-width: 900px;
    margin: 100px auto 10px;
  }

  .mini__text {
    text-align: center;
    font-family: Poppins;
    font-weight: 400px;
    font-size: 14px;
    letter-spacing: 1.3px;
    color: #ffffff;
    opacity: 0.87;
    max-width: 800px;
    margin: auto;
  }

  .card__title {
    font-size: 18px;
    font-weight: bold;
    font-family: Poppins;
    letter-spacing: 1.1px;
    color: #010b1d;
    opacity: 0.87;
  }

  .card__body {
    font-size: 14px;
    font-family: Poppins;
    letter-spacing: 1.1px;
    color: #010b1dab;
    opacity: 1;
  }
}
