.faq__wrapper {
  .title {
    text-align: left;
    font-size: 14px;
    font-weight: 550;
    font-family: Poppins;
    letter-spacing: 1.1px;
    color: #010b1d;
    opacity: 1;
  }

  .content {
    font-size: 14px;
    font-family: Poppins;
    letter-spacing: 1.1px;
    color: #010b1d;
    opacity: 0.87;
    border-left: 5px solid #286cff;
    border-radius: 5px;
  }
}
