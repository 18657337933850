.authenticated__header {
  background-color: #286cff;
  .link__text {
    text-align: left;
    font-size: 12px;
    font-family: Poppins;
    font-weight: bold;
    letter-spacing: 1px;
    color: #fff;
    opacity: 1;
  }
}
